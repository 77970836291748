import Head from 'next/head'
import { useRouter } from 'next/router'

const HOST = process.env.NEXT_PUBLIC_HOST
const onStage = process.env.NEXT_PUBLIC_STAGE === 'true'

export default function StaticSeo () {
  const router = useRouter()
  const underSubdomain = Boolean(router.query.subdomain)

  return <Head>
    <meta property="fb:app_id" content="2561398784175766" />
    <meta property="og:site_name" content="samochody" />
    <meta name="twitter:site" content="@samochody.pl" />
    <meta name="twitter:creator" content="@samochody.pl" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=10, shrink-to-fit=no" />
    <meta key="robots" name="robots" content={onStage ? 'noindex' : 'all'} />
    <meta
				name="google-adsense-account"
				content="ca-pub-9600016495992077"
		/>

    { !underSubdomain && router.locales.map(locale =>
      <link
        key={locale}
        href={locale === 'pl-PL'
          ? `${HOST}${router.asPath}`
          : `${HOST}/${locale}${router.asPath}`}
        hrefLang={locale}
        rel="alternate" /> )}
  </Head>
}