import { useCallback, useEffect, useState } from 'react'

// window.removeEventListener('scrollend', changeValuesAtScrollEnd)
        // window.addEventListener('scrollend', changeValuesAtScrollEnd)
const useWindowScroll = () => {
    const [y, setY] = useState(0)
    const [lastY, setLastY] = useState(0)

    const setScroll = useCallback((_) => {
        const newY = window.scrollY
        if (y !== newY) {
            setLastY(y)
            setY(newY)
        }
    }, [setLastY, setY, y])

    useEffect(() => {
        setScroll()
        window.addEventListener('scroll', setScroll)
        return () => window.removeEventListener('scroll', setScroll)
    }, [setScroll])

    return [y, lastY]
}


export default useWindowScroll