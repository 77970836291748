import { useEffect } from 'react'
import StaticSeo from 'src/seo/components/StaticSeo'
import ContextProvider from 'lib/context'
import SessionGuard from '@components/session/SessionGuard'
import UserChangeListener from '@components/session/UserChangeListener'
import RouteChangeProgress from '@components/progress/RouteChangeProgress'
import Chat from '@components/chat/views/Chat'
import ToTopWidget from '@components/ToTopWidget'
// import { CookieConsent } from '@components/Cookies'
// import OneTimeUserMessages from '@components/OneTimeUserMessages'
// import OneTimeMessageForLoggedInDealers from '@components/OneTimeMessageForLoggedInDealers'
// import OneTimeUserCardReminder from '@components/OneTimeUserCardReminder'
import unregister from '@utils/sw/unregister'
import 'styles/globals.css'

const UNREGISTER_ALL_SERVICE_WORKERS = true

const SPL = ({ Component, pageProps }) => {
  useEffect(() => {
    if (UNREGISTER_ALL_SERVICE_WORKERS) unregister()
  }, [])

  return <>
    <StaticSeo />
    <ContextProvider>
      {/* <OneTimeUserMessages messages={[
        OneTimeMessageForLoggedInDealers,
        OneTimeUserCardReminder,
      ]} /> */}
      {/* <CookieConsent /> */}
      <SessionGuard />
      <UserChangeListener />
      <RouteChangeProgress />
      <Component {...pageProps} />
      <Chat />
      <ToTopWidget />
    </ContextProvider>
  </>
}

export default SPL
